import React, { useState, useEffect } from 'react';
import { variable } from '../../../Variable';
const AddWaterTreatmentModal = ({ show, onClose }) => {
  const [name, setName] = useState('');
  const [types, setTypes] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [error, setError] = useState('');

  // Fetch water treatment types when the component mounts
  useEffect(() => {
    if (show) {
      fetch(variable.API_URL+'/WaterTreatmentType')
        .then(response => response.json())
        .then(data => setTypes(data))
        .catch(error => console.error('Error fetching water treatment types:', error));
    }
  }, [show]);

  // Handle adding a new water treatment
  const handleAddWaterTreatment = () => {
    // Check for required fields
    if (!name || !selectedTypeId) {
      setError('Пожалуйста, заполните все поля.');
      return;
    }

    // Prepare data to be sent
    const data = {
      type_id: selectedTypeId,
      watertreatment_name: name,
    };

    // Send a POST request to add a new water treatment
    fetch(variable.API_URL+'/WaterTreatment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token') 
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(result => {
        if (result === 'Added Successfully') {
          alert('Водоподготовка успешно добавлена!');
          onClose(); // Close the modal after successful addition
          setName(''); // Clear the form fields
          setSelectedTypeId('');
          setError('');
          window.location.reload();
        } else {
          setError('Ошибка при добавлении водоподготовки.');
        }
      })
      .catch(error => {
        setError('Ошибка при отправке запроса.');
        console.error('Error:', error);
      });
  };

  if (!show) return null;

  return (
    <div className="modal show d-block" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить новую водоподготовку</h5>
          </div>
          <div className="modal-body">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="form-group">
              <label>Название водоподготовки</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Тип водоподготовки</label>
              <select
                className="form-control"
                value={selectedTypeId}
                onChange={(e) => setSelectedTypeId(e.target.value)}
                required
              >
                <option value="">Выберите тип</option>
                {types.map((type) => (
                  <option key={type.typeId} value={type.typeId}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Закрыть
            </button>
            <button type="button" className="btn btn-primary" onClick={handleAddWaterTreatment}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWaterTreatmentModal;
