import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { variable } from '../../../Variable';

const EditUser = ({ show, onClose, user }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userType, setUserType] = useState('user'); // Новый стейт для типа пользователя
  const [error, setError] = useState('');

  useEffect(() => {
    if (show && user) {
      setUserName(user.user_name || '');
      setPassword('');
      setConfirmPassword('');
      setEmail(user.mail || '');
      setFirstName(user.first_name || '');
      setLastName(user.last_name || '');
      setUserType(user.user_type || 'user'); // Инициализация стейта типа пользователя
      setError('');
    }
  }, [show, user]);

  const handleEditUser = () => {
    if (password && password !== confirmPassword) {
      setError('Пароль и подтверждение пароля не совпадают.');
      return;
    }

    // Подготовка данных для отправки
    const data = {
      user_name: userName || user.user_name,
      mail: email || user.mail,
      first_name: firstName || user.first_name,
      last_name: lastName || user.last_name,
      user_type: userType || user.user_type,
      password_hash: password ? CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex) : '', // Логика для отправки пустого пароля
    };

    fetch(`${variable.API_URL}/User/${user.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === 'User updated successfully') {
          alert('Пользователь успешно обновлен!');
          window.location.reload();
        } else {
          setError('Ошибка при обновлении пользователя.');
        }
      })
      .catch((error) => {
        setError('Ошибка при отправке запроса.');
        console.error('Error:', error);
      });
  };

  if (!show) return null;

  return (
    <div className="modal show d-block" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Редактировать пользователя</h5>
          </div>
          <div className="modal-body">
            {error && (
              <div
                className="alert alert-danger alert-dismissible fade show position-relative"
                role="alert"
                style={{ paddingRight: '2.5rem' }}
              >
                {error}
                <button
                  type="button"
                  className="close position-absolute"
                  onClick={() => setError('')}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <div className="form-group">
              <label>Имя</label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Фамилия</label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Логин</label>
              <input
                type="text"
                className="form-control"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Пароль (оставьте пустым, если не хотите менять)</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Подтвердите пароль</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Электронная почта</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Тип пользователя</label>
              <select
                className="form-control"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                required
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Закрыть
            </button>
            <button type="button" className="btn btn-dark" onClick={handleEditUser}>
              Сохранить изменения
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
