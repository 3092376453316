import React, { useEffect, useState, useCallback } from 'react';
import { variable } from '../../../Variable';

const WaterTreatmentUserModal = ({ show, onHide, treatment }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [filteredAllUsers, setFilteredAllUsers] = useState([]);
  const [filteredAssignedUsers, setFilteredAssignedUsers] = useState([]);
  const [searchAll, setSearchAll] = useState('');
  const [searchAssigned, setSearchAssigned] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchAllUsers = useCallback(async () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    try {
      const response = await fetch(variable.API_URL + '/User', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAllUsers(data);
      setFilteredAllUsers(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching all users:', error);
      setLoading(false);
    }
  }, []);
  
  const fetchAssignedUsers = useCallback(async () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    try {
      const response = await fetch(`${variable.API_URL}/WaterTreatmentsUser/${treatment.watertreatment_id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch assigned users');
      }
      const data = await response.json();
      setAssignedUsers(data.map((item) => item.user));
      setFilteredAssignedUsers(data.map((item) => item.user));
    } catch (error) {
      console.error('Error fetching assigned users:', error);
    }
  }, [treatment.watertreatment_id]);
  

  useEffect(() => {
    if (show) {
      setLoading(true);
      fetchAllUsers();
      fetchAssignedUsers();
    }
  }, [show, fetchAllUsers, fetchAssignedUsers]);

  const filterAllUsers = useCallback(() => {
    setFilteredAllUsers(
      allUsers.filter((user) =>
        user.user_name.toLowerCase().includes(searchAll.toLowerCase())
      )
    );
  }, [searchAll, allUsers]);

  const filterAssignedUsers = useCallback(() => {
    setFilteredAssignedUsers(
      assignedUsers.filter((user) =>
        user.user_name.toLowerCase().includes(searchAssigned.toLowerCase())
      )
    );
  }, [searchAssigned, assignedUsers]);

  useEffect(() => {
    filterAllUsers();
  }, [searchAll, allUsers, filterAllUsers]);

  useEffect(() => {
    filterAssignedUsers();
  }, [searchAssigned, assignedUsers, filterAssignedUsers]);

  const handleAddUser = async (user) => {
    try {
      const response = await fetch(`${variable.API_URL}/UserWaterTreatments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token') 
        },
        body: JSON.stringify({
          user_id: user.user_id,
          watertreatment_id: treatment.watertreatment_id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add user');
      }

      setAssignedUsers((prevAssignedUsers) => [...prevAssignedUsers, user]);
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleRemoveUser = async (user) => {
    try {
      const response = await fetch(
        `${variable.API_URL}/UserWaterTreatments?user_id=${user.user_id}&watertreatment_id=${treatment.watertreatment_id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') 
        }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to remove user');
      }

      setAssignedUsers((prevAssignedUsers) =>
        prevAssignedUsers.filter((u) => u.user_id !== user.user_id)
      );
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const isUserAssigned = (id) => {
    return assignedUsers.some((user) => user.user_id === id);
  };

  if (!show) return null;

  return (
    <div className="modal show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{treatment.watertreatment_name}</h5>
          </div>
          <div className="modal-body">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <h5>Все пользователи</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Поиск по имени..."
                    value={searchAll}
                    onChange={(e) => setSearchAll(e.target.value)}
                  />
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {filteredAllUsers.map((user) => (
                      <div key={user.user_id} className="d-flex flex-column mb-3 p-2 border rounded">
                        <div className="mb-1"><strong>{user.user_name}</strong></div>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleAddUser(user)}
                          disabled={isUserAssigned(user.user_id)}
                        >
                          {isUserAssigned(user.user_id) ? 'Добавлен' : 'Добавить'}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>Пользователи водоподготовки</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Поиск по имени..."
                    value={searchAssigned}
                    onChange={(e) => setSearchAssigned(e.target.value)}
                  />
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {filteredAssignedUsers.map((user) => (
                      <div key={user.user_id} className="d-flex flex-column mb-3 p-2 border rounded">
                        <div className="mb-1"><strong>{user.user_name}</strong></div>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleRemoveUser(user)}
                        >
                          Снять
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onHide}>
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterTreatmentUserModal;
