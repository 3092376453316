// ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles }) => {
  // Получение данных о пользователе из localStorage
  const user = JSON.parse(localStorage.getItem('user'));

  // Проверка, авторизован ли пользователь и имеет ли он доступ
  if (!user || !allowedRoles.includes(user.user_type)) {
    // Перенаправление на страницу входа, если пользователь не авторизован
    return <Navigate to="/admin/login" />;
  }

  // Если пользователь авторизован и имеет доступ, отобразить дочерние маршруты
  return <Outlet />;
};

export default ProtectedRoute;
