import React, { Component } from 'react';
import AdminHeader from '../Header';
import AddUserButtonWithModal from './Modal/AddUserButtonWithModal';
import UserTable from './UserTable';

export class Users extends Component {
  render() {
    return (
      <div>
        <AdminHeader /> {/* Добавляем компонент AdminHeader */}
        <div className="m-3 d-flex justify-content-center">
          {/* Кнопка добавления пользователя */}
          <AddUserButtonWithModal />
        </div>
        <div className="m-3">
          {/* Таблица пользователей */}
          <UserTable />
        </div>
      </div>
    );
  }
}

export default Users;
