import React, { Component } from 'react';
import { variable } from '../../Variable';
import AddPhoto from './Modal/AddPhoto';

class WaterTreatmentName extends Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().substr(0, 10); // YYYY-MM-DD формат
    this.state = {
      date: today,
      parameters: [], // Данные параметров
      showAddPhotoModal: false,
      currentParamId: null,
      paramTitle: null,
      userRole: JSON.parse(localStorage.getItem('user')).user_type, // Получаем роль пользователя
    };
  }

  // Обработчик изменения даты
  handleDateChange = (event) => {
    this.setState({ date: event.target.value }, () => {
      this.fetchData(); // Получаем данные после изменения даты
    });
  };
  

  // Обработчик нажатия на кнопку "Сохранить отчет"
  handleSaveReport = () => {
    const { treatment } = this.props;
    const { date } = this.state;
    const token = localStorage.getItem('token'); 
    fetch(variable.API_URL + '/Report/generateReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token 
      },
      body: JSON.stringify({
        waterTreatmentId: treatment.watertreatment_id,
        WaterTreatmentName: treatment.watertreatment_name,
        date: date,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Ошибка при генерации отчета');
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Отчет_${treatment.watertreatment_name}_${date}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке отчета:", error);
      });
  };

  // Получение данных с сервера
  fetchData = () => {
    const { date } = this.state;
    const { treatment } = this.props;
    const token = localStorage.getItem('token'); 

    fetch(variable.API_URL + `/WaterTreatmentIndications/${date}/${treatment.watertreatment_id}`, {
        method: 'GET', // Указываем метод (по умолчанию 'GET', но для ясности можно указать явно)
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token // Добавляем токен в заголовок
        }
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Ошибка ${response.status}: ${response.statusText}`); // Обрабатываем ошибки
        }
        return response.json();
    })
    .then((data) => {
        this.setState({ parameters: data });
    })
    .catch((error) => {
        console.error("Ошибка при получении данных:", error);
    });
};

  // Функция для вычисления диапазона дат
  getCurrentDay = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(today.getDate()); 
    const lastDayOfWeek = new Date(today.getDate()); 

    return {
      min: firstDayOfWeek.toISOString().substr(0, 10),
      max: lastDayOfWeek.toISOString().substr(0, 10),
    };
  };

  // Обработчик изменения значения параметра
  handleValueChange = async (parametrId, event) => {
    const { userRole } = this.state;
    if (userRole === 'admin') return; // Админ не может редактировать

    const newValue = event.target.value;
    const { date } = this.state;

    this.setState((prevState) => ({
      parameters: prevState.parameters.map((param) =>
        param.parametr_id === parametrId ? { ...param, value: newValue } : param
      ),
    }));

    try {
      const { treatment } = this.props;
      const token = localStorage.getItem('token');
      const response = await fetch(variable.API_URL + '/WaterTreatmentIndications/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token 
        },
        body: JSON.stringify({
          watertreatment_id: treatment.watertreatment_id,
          parametr_id: parametrId,
          date: date,
          value: newValue,
          user_id: JSON.parse(localStorage.getItem('user')).user_id,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при отправке данных');
      }

      const result = await response.json();
      console.log('Данные успешно отправлены:', result);
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  // Обработчик добавления фото
  handleAddPhoto = (param) => {
    const { userRole } = this.state;
    this.setState({ showAddPhotoModal: true, parametr_title: param.title, parametr_id: param.parametr_id });

  };
  // Обработчик закрытия модального окна
  handleCloseModal = () => {
    this.setState({ showAddPhotoModal: false, currentParamId: null });
  };

  // Обработчик удаления фото
handleDeletePhoto = async (value_id) => {
  console.log(value_id);
  try {
    const formData = new FormData();
    formData.append('value_id', value_id);

    const token = localStorage.getItem('token');
    const response = await fetch(variable.API_URL + '/Photo/delete', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + token 
      }
    });

    if (!response.ok) {
      throw new Error('Ошибка при удалении фото');
    }

    const result = await response.json();

    // Обновляем данные
    this.fetchData();
  } catch (error) {
    console.error('Ошибка при удалении фото:', error);
  }
};


   // Обработчик загрузки фото
   handleSavePhoto = async (file) => {
    const { parametr_title, date, parametr_id } = this.state;
    const { treatment } = this.props;
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('parameterName', parametr_title);
    formData.append('parametr_id', parametr_id);
    formData.append('date', date);
    formData.append('watertreatment_id', treatment.watertreatment_id);
    formData.append('waterTreatmentName', treatment.watertreatment_name);
    formData.append('user_id', JSON.parse(localStorage.getItem('user')).user_id);

    try {
      const response = await fetch(variable.API_URL + '/Photo/upload', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + token 
      }
      });

      if (!response.ok) {
        throw new Error('Ошибка при загрузке фото');
      }

      const result = await response.json();
      console.log('Фото успешно загружено:', result);

      // Обновите параметры, если необходимо
      this.fetchData(); // Или обновите состояние, если нужно

    } catch (error) {
      console.error('Ошибка при загрузке фото:', error);
    }
    this.fetchData();
  };

  componentDidMount() {
    this.fetchData(); // Получаем данные при загрузке страницы
  }
  fetchPhoto = async (photoPath) => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    const response = await fetch(`${variable.API_URL}/Photo/getphoto?photoPath=${encodeURIComponent(photoPath)}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token // Добавляем токен в заголовок
        }
    });

    if (!response.ok) {
        throw new Error(`Ошибка ${response.status}: ${response.statusText}`);
    }

    return response.blob(); // Возвращаем blob-объект
};

  render() {
    const { treatment } = this.props;
    const { date, parameters, showAddPhotoModal, userRole } = this.state;
    const currentDay = new Date();
    const formattedCurrentDay = currentDay.toISOString().split('T')[0];
    return (
      <div className="container mt-4">
        <div className="table-responsive">
          <div className="w-auto d-flex align-items-center"> 
            <h2>{treatment.watertreatment_name}</h2>
            <input
              type="date"
              value={date}
              onChange={this.handleDateChange}
              className="form-control w-auto mx-2"
              min={userRole === 'user' ? formattedCurrentDay : undefined} // Ограничиваем выбор дат
              max={userRole === 'user' ? formattedCurrentDay : undefined}
            />
            <button
              className="btn btn-success"
              onClick={this.handleSaveReport}
            >
              Сохранить отчет
            </button>
          </div>

          {/* Таблица параметров */}
          <table className="table table-striped w-auto mx-auto">
            <thead>
              <tr>
                <th>Имя параметра</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              {parameters.map((param) => (
                <tr key={param.parametr_id}>
                  <td>
                    {param.title}
                    {/* Кнопка "Посмотреть фото" */}
                    {param.photo_path && (
                      <div className="mt-2">
                          <button
                              onClick={async () => {
                                  try {
                                      const blob = await  this.fetchPhoto(param.photo_path);
                                      const url = URL.createObjectURL(blob); // Создаем URL для blob
                                      const newTab = window.open(url); // Открываем новое окно с изображением
                                      newTab.focus();
                                  } catch (error) {
                                      console.error("Ошибка при загрузке фото:", error);
                                  }
                              }}
                              className="btn btn-info btn-sm"
                          >
                              Посмотреть фото
                          </button>
                      </div>
                  )}
                  </td>
                  <td>
                    {userRole === 'user' ? (
                      <input
                        type="text"
                        value={param.value || ''}
                        onChange={(event) => this.handleValueChange(param.parametr_id, event)}
                        className="form-control"
                      />
                    ) : (
                      <span>{param.value || 'Нет данных'}</span> // Админ не может редактировать значения
                    )}
                    <div className="mt-2 d-flex flex-column flex-sm-row">
                      {userRole === 'user' && (
                        param.photo_path ? (
                          <>
                            <button
                              className="btn btn-warning btn-sm me-1"
                              onClick={() => this.handleAddPhoto(param)}
                            >
                              Изменить фото
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => this.handleDeletePhoto(param.value_id, param.parametr_id, param.photo_path)}
                            >
                              Удалить фото
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => this.handleAddPhoto(param)}
                          >
                            Добавить фото
                          </button>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Модальное окно для добавления фото */}
        <AddPhoto
          show={showAddPhotoModal}
          handleClose={this.handleCloseModal}
          handleSave={this.handleSavePhoto}
        />
      </div>
    );
  }
}

export default WaterTreatmentName;
