import React, { Component } from 'react';
import { variable } from '../../Variable';
import EditUser from './Modal/EditUser';
import UserWaterTreatment from './Modal/UserWaterTreatment';
export class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      showEditModal: false,
      selectedUser: null,
      showWaterTreatmentModal: false, 
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(variable.API_URL + '/User',{
        method:'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
    });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      this.setState({ users: data, loading: false });
    } catch (error) {
      console.error('Error fetching users:', error);
      this.setState({ loading: false });
    }
  };

  handleWaterTreatments = (user) => {
    this.setState({ 
      showWaterTreatmentModal: true, 
      selectedUser: user 
    });
  };

  handleCloseWaterTreatmentModal = () => {
    this.setState({
      showWaterTreatmentModal: false,
      selectedUser: null,
    });
  };

  handleEdit = (userId) => {
    const userToEdit = this.state.users.find(user => user.user_id === userId);
    this.setState({
      selectedUser: userToEdit,
      showEditModal: true,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      showEditModal: false,
      selectedUser: null,
    });
  };

  handleDelete = async (userId) => {
    if (window.confirm(`Are you sure you want to delete user ${userId}?`)) {
      try {
        const response = await fetch(variable.API_URL + `/User/${userId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        });
        const result = await response.json();
        if (response.ok) {
          alert(result);
          this.fetchUsers();
        } else {
          alert(result);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user');
      }
    }
  };

  render() {
    const { users, loading, showEditModal, selectedUser, showWaterTreatmentModal } = this.state;
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Имя</th>
              <th>Фамилия</th>
              <th>Логин</th>
              <th>Email</th>
              <th>Тип пользователя</th> {/* Добавьте это, если хотите отображать тип пользователя */}
              <th className="d-none d-md-table-cell"></th>
              <th className="d-none d-md-table-cell"></th>
              <th className="d-none d-md-table-cell"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id}>
                <td>{user.user_id}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.user_name}</td>
                <td>{user.mail}</td>
                <td>{user.user_type}</td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => this.handleWaterTreatments(user)}
                  >
                    Водоподготовки
                  </button>
                </td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => this.handleEdit(user.user_id)}
                  >
                    Редактировать
                  </button>
                </td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-dark btn-sm ml-2"
                    onClick={() => this.handleDelete(user.user_id)}
                  >
                    Удалить
                  </button>
                </td>
  
                {/* Mobile buttons - visible on smaller screens */}
                <td className="d-md-none d-block">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-secondary btn-sm mb-1"
                      onClick={() => this.handleWaterTreatments(user)}
                    >
                      Водоподготовки
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mb-1"
                      onClick={() => this.handleEdit(user.user_id)}
                    >
                      Редактировать
                    </button>
                    <button
                      className="btn btn-dark btn-sm mb-1"
                      onClick={() => this.handleDelete(user.user_id)}
                    >
                      Удалить
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
  
        {showEditModal && selectedUser && (
          <EditUser
            show={showEditModal}
            onClose={this.handleCloseEditModal}
            user={selectedUser}
          />
        )}
  
        {showWaterTreatmentModal && selectedUser && (
          <UserWaterTreatment
            show={showWaterTreatmentModal}
            user={selectedUser}
            onClose={this.handleCloseWaterTreatmentModal}
          />
        )}
      </div>
    );
  }
  
}

export default UserTable;
