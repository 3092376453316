import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminHeader from '../../Admin/Header';
import UserHeader from '../../User/Components/UserHeader';
import WaterTreatmentName from './WaterTreatmentName';

const WaterTreatmentIndications = () => {
  const location = useLocation();
  const { treatment } = location.state || {}; 

  // Получение данных о пользователе из localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user && user.user_type === 'admin';
  const isUser = user && user.user_type === 'user';
  return (
    <div>
      {isAdmin && <AdminHeader />} 
      {isUser && <UserHeader />} 
      {console.log(treatment)}
      <div className="container mt-4">
        <h2>Показания водоподготовки</h2>
        {treatment ? (
        <WaterTreatmentName treatment={treatment} /> 
      ) : (
        <p>Данные о водоподготовке не найдены.</p>
      )}
      </div>
    </div>
  );
};

export default WaterTreatmentIndications;
