import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Correct import for useNavigate
import EditWaterTreatmentModal from './Modal/EditWaterTreatmentModal';
import WaterTreatmentUserModal from './Modal/WaterTreatmentUser';
import { variable } from '../../Variable';
const WaterTreatmentTable = () => {
  const [waterTreatments, setWaterTreatments] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false); // Новое состояние для показа модального окна пользователей

  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
  
    // Запрос для получения WaterTreatment
    fetch(variable.API_URL + '/WaterTreatment', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch WaterTreatment');
        }
        return response.json();
      })
      .then((data) => setWaterTreatments(data))
      .catch((error) => console.error(error));
  
    // Запрос для получения WaterTreatmentType
    fetch(variable.API_URL + '/WaterTreatmentType', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch WaterTreatmentType');
        }
        return response.json();
      })
      .then((data) => setTypes(data))
      .catch((error) => console.error(error));
  }, []); 
  
  const handleViewIndicationsClick = (treatment) => {
    
    navigate('/WaterTreatmentIndications', { state: { treatment } });
  };

  const handleEditClick = (treatment) => {
    setSelectedTreatment(treatment);
    setShowEditModal(true);
  };

  const handleWaterTreatmentUserClick = (treatment) => {
    setSelectedTreatment(treatment);
    setShowUserModal(true);
  };

  const handleDeleteClick = async (treatment) => {
    if (window.confirm(`Вы уверены, что хотите удалить водоподготовку ${treatment.watertreatment_id}?`)) {
      try {
        const response = await fetch(variable.API_URL + `/WaterTreatment/${treatment.watertreatment_id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token') 
        }
        });
        const result = await response.json();
        if (response.ok) {
          alert(result);
          setWaterTreatments((prevTreatments) =>
            prevTreatments.filter((item) => item.watertreatment_id !== treatment.watertreatment_id)
          );
        } else {
          alert(result);
        }
      } catch (error) {
        console.error('Ошибка при удалении водоподготовки:', error);
        alert('Ошибка при удалении водоподготовки');
      }
    }
  };

  const handleSave = (updatedTreatment) => {
    setWaterTreatments((prevTreatments) =>
      prevTreatments.map((treatment) =>
        treatment.watertreatment_id === updatedTreatment.watertreatment_id ? updatedTreatment : treatment
      )
    );
    setShowEditModal(false);
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center">Водоподготовки</h2>
      <div className="table-responsive">
        <table className="table table-striped  mx-auto">
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Тип</th>
              <th className="d-none d-md-table-cell"></th>
              <th className="d-none d-md-table-cell"></th>
              <th className="d-none d-md-table-cell"></th>
            </tr>
          </thead>
          <tbody>
            {waterTreatments.map((treatment) => (
              <tr key={treatment.watertreatment_id}>
                <td>{treatment.watertreatment_id}</td>
                <td>{treatment.watertreatment_name}</td>
                <td>{treatment.type_name}</td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleViewIndicationsClick(treatment)}
                  >
                    Показания
                  </button>
                </td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleWaterTreatmentUserClick(treatment)}
                  >
                    Пользователи
                  </button>
                </td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleEditClick(treatment)}
                  >
                    Редактировать
                  </button>
                </td>
                <td className="d-none d-md-table-cell">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => handleDeleteClick(treatment)}
                  >
                    Удалить
                  </button>
                </td>
                {/* Кнопки для мобильных устройств */}
                <td className="d-md-none d-block">
                  <div className="d-flex flex-column">
                  <button
                      className="btn btn-secondary btn-sm mb-1"
                      onClick={() => handleViewIndicationsClick(treatment)}
                    >
                      Показания
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mb-1"
                      onClick={() => handleWaterTreatmentUserClick(treatment)}
                    >
                      Пользователи
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mb-1"
                      onClick={() => handleEditClick(treatment)}
                    >
                      Редактировать
                    </button>
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => handleDeleteClick(treatment)}
                    >
                      Удалить
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedTreatment && showEditModal && (
        <EditWaterTreatmentModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          onSave={handleSave}
          treatment={selectedTreatment}
          types={types}
        />
      )}

      {selectedTreatment && showUserModal && (
        <WaterTreatmentUserModal
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
          treatment={selectedTreatment}
        />
      )}
    </div>
  );
};

export default WaterTreatmentTable;
