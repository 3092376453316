import React, { Component } from 'react';
import logotype from './logotype.svg';
import CryptoJS from 'crypto-js';
import Alert from '../Components/Alert';
import { variable } from '../Variable';

export class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      alertMessage: '',
      alertType: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;

    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

    const data = {
        user_name: username,
        password_hash: hashedPassword,
    };

    fetch(variable.API_URL + '/Admin', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((result) => {
            if (result.token) {
                // Сохраняем данные пользователя в localStorage
                localStorage.setItem('user', JSON.stringify(result.userData));
                // Сохраняем токен
                localStorage.setItem('token', result.token);
                console.log(result);
                this.setState({ alertMessage: 'Вход выполнен', alertType: 'success' });
                if(result.userData.user_type === "admin"){
                  window.location.href = '/Admin/WaterTreatments';  
                }
                if(result.userData.user_type === "user"){
                    window.location.href = '/Selecting';  
                  }
                

            } else {
                this.setState({ alertMessage: result, alertType: 'danger' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            this.setState({ alertMessage: 'Произошла ошибка при попытке входа. Пожалуйста, попробуйте еще раз.', alertType: 'danger' });
        });
}


  handleCloseAlert() {
    this.setState({ alertMessage: '', alertType: '' });
  }

  render() {
    const { alertMessage, alertType } = this.state;

    return (
      <div className="container mt-3">
        {alertMessage && (
          <div className="alert-container">
            <Alert message={alertMessage} type={alertType} onClose={this.handleCloseAlert} />
          </div>
        )}
        <div className="d-flex justify-content-center">
          <form className="form-container" onSubmit={this.handleSubmit}>
                        <div className="text-center mb-4">
              <img src="https://yandex-images.clstorage.net/4Utb8x216/1c7691FMCU/9COoFTypWZvwUglicydLVGMKE2VEhLN05uglNc9s-IA3XfOpjWm1SG9eJW9sSXYLR83p94ykHeuTHUud1w6NkbB6vUd-uBSQohVDNid7HAIpEkgtWca7LOr_NXXDevPVJkP38pwz7RNoVUJWFGpQYjupmIb9j1zv21sM9-wbl4HgF5kHGYMBlNxFYlhkJEFDaE0XREnyviX21UtU52ztxiIK4_aATuFKAQx5eCJGYXgOgomuxoRp5aRROOTpd4uz7AyvIyiLHbDeQ0RSSCJeeUpyJGp_4Jcj4MZ5bs1B-u4YNODmr2nIeSE2TEY5ZXxPDKumkfGFVOOkTS_B4GO7l-8JkQMWjTG-2URIVWcTSWdRawlnQMeqO-bITEDceNz5FjfY0L1r9GkXNkpwLVl4VCevmaDGo2HkkGsp2-B1u6XXALk2AKkfhtNNVk5GM2RkSVAreELkkhHqwnFWz0HLxxIS0dqbTuB0GjBrSDlnVUoft6K245dgxJlNIe_GUqSZ9DCdMD6VM7Pad0tqVDx3aHVUNVZ055ISzOFwTMp98c8ABP3oqkn1TxgnfE46XGZtAZmXoPm2ROafegPA0n6rhMcguR04kw-c0klGXkU-TVJIdyVZZcOdO9nIbmb5aNnROCTf05RBwlQdA3VqOmdlWxWftqT2llX8gksWw-JziaTtCp0ANrgGp850UH9GHW1nX3M3R1PZswLG5lRF-njT5j8BxdqnZtJlEgx2QzJxdXgZv4CDzIVx1KV6Murdab2GzQ2yJwWtMYD8R1d3UQBYfl9VMWN8wJc7wcpae-95-MYcJMTVunHLSh0WSHgHU1VzJo-5q_evY-ynWhvr9V-ZmvUGjCcKsAOx10pIa2ERRVlrURloQuqpAOLmWXDtZtbvOivP5aVCz1kdJXVEEVpWTx6onZvcrknghX0t58B3i4_gKZUwGpw2ht5ma2JqGmFccXYPZlzpow3Fw39m7mg" alt="Logotype" width="350" />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="username">Имя пользователя</label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                placeholder=""
                value={this.state.username}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="password">Пароль</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder=""
                value={this.state.password}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className="form-check mt-3">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              Войти в систему
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default LogIn;
