import React from 'react';
import { useNavigate } from 'react-router-dom';

const UserHeader = () => {
  const navigate = useNavigate();

  // Получение данных пользователя из localStorage
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    // Удалите данные о пользователе из localStorage
    localStorage.removeItem('user');

    // Перенаправьте на страницу входа
    navigate('/LogIn');
  };

  return (
    <header className="p-3 text-bg-dark" >
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li><a href="/Selecting" className="nav-link px-2 text-white">Водоподготовки</a></li>
          </ul>
          <div className="text-center text-md-start mx-3">
            {/* Вывод user_name и user_type */}
            {user && (
              <div className="text-white">
                <span className="me-3">{user.user_name}</span>
              </div>
            )}
          </div>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-outline-light me-2"
              onClick={handleLogout}
            >
              Выйти
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UserHeader;
